
const formatPhoneNumber = (number, hidden = false) => {
    if (!number) {
        return;
    }

    const numStr = number.toString();

    const formattedNumber = numStr.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, `($1) $2 $3-${hidden ? "****" : "$4"}`);

    return formattedNumber;
}

const formatCPF = (cpf, hidden) => {
    if (!cpf) {
        return;
    }
    // Ensure the input is a string
    cpf = cpf.toString();

    // Use a regular expression to format the CPF
    const formattedCPF = cpf.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, `$1.${hidden ? "***" : "$2"}.${hidden ? "***" : "$3"}-${hidden ? "**" : "$4"}`);

    return formattedCPF;
}

const formatToBRL = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);
}

const formatToHumanNumber = (value) => {
    return new Intl.NumberFormat('pt-BR').format(value);
}

export { formatPhoneNumber, formatCPF, formatToBRL, formatToHumanNumber };