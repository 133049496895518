import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMask from "react-input-mask";

export default function InputPhone({ value, withCountryCode = false, onChange, label = 'Telefone', ...props }) {

    const maskSwitch = () => {
        if (value.replace(/[^0-9.]/g, '').length <= 10) {
            return '(99) 9999-99999';
        } else {
            return '(99) 99999-9999';
        }
    }

    return (
        <InputMask
            mask={maskSwitch(value || '')}
            maskChar=""
            value={value || ''}
            onChange={onChange}>
            {() => <TextField fullWidth label={label} {...props} />}
        </InputMask>
    );
}
