"use client"
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../../public/logo.jpg';
import Image from 'next/image';
import { Icon } from "@iconify/react"
import { useRouter } from 'next/navigation';
import { makeStyles } from '@mui/styles';
import { getCookies, deleteCookie } from 'cookies-next';
import { useAuthContext } from '@/auth/hooks';
import { AuthContext } from '@/contexts/auth-context';
import { Avatar } from '@mui/material';
import { formatPhoneNumber } from '@/helpers/formatter';
import { useConfig } from '@/contexts/config-context';
import ModalLogin from '../modals/modal-login';


const drawerWidth = 240;
let navItems = [
    {
        title: "Home",
        href: "/",
        icon: "oi:home"
    },
    {
        title: "Afiliados",
        href: "/affiliate",
        icon: "lucide:award"
    },
    {
        title: "Campanhas",
        href: "/campaigns",
        icon: "gg:list"
    },
    {
        title: "Ganhadores",
        href: "/winners",
        icon: "ion:trophy"
    },
    {
        title: "Meus títulos",
        href: "/my-tickets",
        icon: "typcn:ticket"
    },
    {
        title: "Caixas surpresa",
        href: "/mistery-box",
        icon: "typcn:gift"
    },
    {
        title: "Termos de uso",
        href: "/terms",
        icon: "gg:list"
    },

];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
        color: 'black'
    },
    title: {
        flexGrow: 1,
        color: 'black'
    },
    appBarTransparent: {
        backgroundColor: 'rgba(0,0,0, .8)!important'
    },
    appBarSolid: {
        backgroundColor: 'black!important'
    }
}));

function Header() {
    const configData = useConfig();
    const { user } = React.useContext(AuthContext);
    const [showModalLogin, setShowModalLogin] = React.useState(false);

    if (user == null) {
        navItems.pop();
        navItems.push({
            title: "Login",
            action: () => {
                setShowModalLogin(true);
            },
            type: "button",
            icon: 'ion:exit'
        });
    } else {
        navItems.pop();
        navItems.push({
            title: "Sair",
            action: () => {
                window.localStorage.removeItem("token")
                window.location.reload();
            },
            type: "button",
            icon: 'ion:exit'
        });
    }

    const classes = useStyles();
    const router = useRouter();
    const [navBackground, setNavBackground] = React.useState('appBarSolid');
    const context = React.useContext(AuthContext)

    const navRef = React.useRef()
    navRef.current = navBackground
    // const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    React.useEffect(() => {
        if (typeof window == "undefined") {
            return
        }
        const handleScroll = () => {
            const show = window.scrollY < 300
            if (show) {
                setNavBackground('appBarSolid')
            } else {
                setNavBackground('appBarTransparent')
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box sx={{ textAlign: 'center', background: "black", height: "100vh", width: "100vw" }}>
            <Box sx={{ maxWidth: 700, margin: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <div className='flex justify-center items-center ml-2' >
                        <img width={120} height={120} src={configData?.logo_path} className='my-4' />
                    </div>
                    <IconButton onClick={handleDrawerToggle} sx={{ color: "rgb(33, 43, 54)" }}>
                        <Icon icon="mdi:close" fontSize={40} />
                    </IconButton>
                </Box>


                <List style={{ backgroundColor: "black" }}>
                    <div style={{ height: 1, width: "100%", backgroundColor: "rgba(108, 117, 125, 0.3)" }} />
                    {
                        context.user && <>
                            <Box display="flex" gap={2} mt={3} mb={3} alignItems="center">
                                <Avatar
                                    sx={{
                                        borderRadius: 1,
                                        width: 70,
                                        height: 70
                                    }} src='/user.jpg' />
                                {/* {console.log(context.user.phone, formatPhoneNumber(context.user?.phone))} */}
                                <Box display="flex" flexDirection="column" textAlign={"left"}>
                                    <Typography color={"white"} fontWeight="bold" variant='h6'>{context.user?.name}</Typography>
                                    <Typography color={"white"}>{formatPhoneNumber(context.user?.phone)}</Typography>
                                </Box>
                            </Box>
                            <div style={{ height: 1, width: "100%", backgroundColor: "rgba(108, 117, 125, 0.3)" }} />
                        </>
                    }

                    {navItems.map((item, index) => {
                        if (item.type == "button") {
                            return (
                                <Button key={index} onClick={item.action} fullWidth variant='contained' color='primary'>
                                    <Icon icon={item.icon} style={{ marginRight: 10 }} fontSize={20} />
                                    {item.title}</Button>
                            );
                        }
                        return (
                            <div key={"a" + index}>
                                <ListItem
                                    onClick={() => {
                                        router.push(item.href);
                                        handleDrawerToggle();
                                    }}
                                    disablePadding
                                    sx={{
                                        background: "black",
                                        py: 1
                                    }}>
                                    <ListItemButton sx={{ color: "rgb(108, 117, 125)" }}>
                                        <Divider sx={{ color: "blue", bgcolor: 'blue' }} />
                                        <Icon icon={item.icon} style={{ marginRight: 10 }} fontSize={20} />
                                        <b>{item.title}</b>
                                    </ListItemButton>
                                </ListItem>
                                <div style={{ height: 1, width: "100%", backgroundColor: "rgba(108, 117, 125, 0.3)" }} />
                            </div>
                        )
                    })}
                </List>
            </Box>
        </Box>
    );

    const container = typeof window !== "undefined" ? () => window.document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }} >
            {
                showModalLogin && <ModalLogin open={showModalLogin} onClose={() => setShowModalLogin(false)} />
            }
            <CssBaseline />
            <div style={{
                zIndex: 100,
                position: "absolute",
                top: 0,
                left: 0,
                height: 65,
                width: "100%",
                // backgroundColor: "black",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <AppBar component="nav" position="fixed" className={classes[navRef.current]} style={{
                    zIndex: 999,
                    backgroundColor: "black",
                    // position: "relative",
                    // alignSelf: 'center',
                    // margin: "auto",

                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <div style={{
                            maxWidth: 600,
                            width: "100%",
                        }}>
                            <Toolbar
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                variant='dense' style={{ height: 65 }} className='flex justify-between'>

                                <div onClick={() => router.push("/")} >
                                    <img width={100} height={100} src={configData?.logo_path} />
                                </div>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, color: 'white' }}
                                >
                                    <Icon icon="eva:menu-2-fill" />
                                </IconButton>
                            </Toolbar>
                        </div>
                    </div>

                </AppBar>
            </div>

            <div style={{
                position: "absolute",
                width: "100%",
                height: 20,
                zIndex: 50,
                backgroundColor: "black",
                marginTop: 65
            }} />
            <nav>
                <Drawer
                    anchor='top'

                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "100%" },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default Header;